header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--black);
  padding: 0.5rem 1rem;
  background-color: var(--black);
}

header span {
  font-size: 12px;
  font-weight: 600;
}

span.title {
  padding: 0 16px;
}

header a {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  color: var(--dark-blue);
}

header a.new-tab-link {
  border: 1px solid var(--dark-grey);
  border-radius: 8px;
}

a:visited {
  color: var(--dark-blue);
}

a:focus {
  color: var(--dark-blue);
}

a:active {
  color: var(--turquoise);
}

a:hover {
  color: var(--turquoise);
}

.header-section {
  display: flex;
  align-items: center;
}
